<template>
  <div class="login-page">
    <Modal shown unclosable>
      <h2 slot="header" class="offset-3 col">
        {{ $t("auth.recoveryPassword2") }}
      </h2>
      <form slot="body" @submit.prevent="submitForm()">
        <div class="row py-3">
          <div class="col-3 align-self-center form-label">Email</div>
          <div class="col-9">
            <input type="text" v-model="email" class="form-input" />
          </div>
        </div>
        <div class="row py-3">
          <div class="offset-3 col-9">
            <div class="d-flex">
              <button type="submit" class="form-btn">
                {{ $t("common.recover") }}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div
        v-if="isRegisterVisible"
        slot="footer"
        class="offset-3 col"
      >
        <router-link
          :to="{ name: 'login', query: $route.query }"
          class="col align-self-center modal-link"
        >
          {{ $t("auth.logIn") }}
        </router-link>
        {{ $t("common.or") }}
        <router-link
          :to="{ name: 'registration', query: $route.query }"
          class="col align-self-center modal-link"
        >
          {{ $t("auth.registration2") }}
        </router-link>
      </div>
      <div v-else slot="footer" class="d-flex justify-content-center">
        <router-link
          :to="{ name: 'login', query: $route.query }"
          class="modal-link"
        >
          {{ $t("auth.logIn") }}
        </router-link>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Notification } from 'element-ui';
import Modal from '@/components/Modal.vue';

export default {
  name: 'PasswordReset',
  components: {
    Modal,
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    isRegisterVisible() {
      return !(+process.env.VUE_APP_HIDE_LOGIN_FOOTER);
    },
  },
  methods: {
    submitForm() {
      const { email } = this;
      if (email.length > 0) {
        const loading = this.$loading({
          lock: true,
          background: 'rgba(255, 255, 255, 0.7)',
        });

        this.$store
          .dispatch('authDataRequest', {
            action: 'password/forgot',
            method: 'POST',
            data: { email },
          })
          .then(() => {
            Notification.success({
              title: this.$t('notification.success'),
              message: this.$t('notification.letterSentEmail'),
            });
            this.$router.push('login');
          })
          .catch(() => {
            Notification.error({
              title: this.$t('notification.error'),
              message: this.$t('notification.errorCheckCorrectnessInput'),
            });
          })
          .finally(() => {
            loading.close();
          });
      } else {
        Notification.warning({
          title: this.$t('common.attention'),
          message: this.$t('notification.errorEnterPassword'),
        });
      }
    },
  },
};
</script>
